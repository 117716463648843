import * as React from 'react'

//  
interface themeContextValue {
    theme: string
    changeTheme: (theme: string) => void
}
const theme: themeContextValue = {
    theme: 'dark',
    changeTheme: () => {}
}
export const themeContext = React.createContext(theme)

//  provider
export const Provider: React.FC = ({ children }) => {
    const [theme, setTheme] = React.useState(localStorage.theme?localStorage.theme:'dark');
    const changeTheme = React.useCallback(
        (theme: string) => {
            setTheme(theme)
        },
        [theme]
    )
    return (
        <themeContext.Provider value={{ theme, changeTheme }}>
			{children}
        </themeContext.Provider>
    )
}
