import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import React,{useEffect} from 'react'
import Header from './common/header'
import Footer from './common/footer'

import Home from './pages/home'
import Swap from './pages/aaa'
import Dao from './pages/dao'

function App() {
	useEffect(() => {
		 
	}, [])
	
  return (
	<div className="app relative dark:bg-dark bg-white overflow-hidden">
		<BrowserRouter>
			<Header></Header>
			<Routes>
				<Route path="/" element={<Navigate to="/Home" />}></Route>
				<Route path="/Home" element={<Home />}></Route>
				{/* <Route path="/Swap" element={<Swap />}></Route> */}
				<Route path="/Dao" element={<Dao />}></Route>
			</Routes>
			<Footer></Footer>
		</BrowserRouter>
	</div>
  );
}

export default App;
