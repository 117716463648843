import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import vision from '../../../assets/v2/vision.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="">
    	<div className="w-full">
    		<div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-6">
    			<div className="lg:flex justify-center items-center">
					<img  data-aos="zoom-in" className="p-4 h-auto w-full lg:h-auto lg:w-2/3 mx-auto" src={vision}/>
    				<div className="text-center lg:text-left">
						<div className="text-5xl dark:text-white text-gray-900">VISION</div>
						<div className="mt-8 text-base sm:text-xl dark:text-gray-500 text-gray-900">
							FAMDAO is built on a custom protocol as the underlying support to create a more comprehensive one-stop Web3 application platform, achieving autonomy, co-construction, sharing, and promoting the great development of the Web3 market!
						</div>
					</div>
    			</div>
    			
    		</div>
    	</div>
    </div>
  );
}

