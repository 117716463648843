import React,{useEffect,useState} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import bannerBg from '../../../assets/v2/banner-dao.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="relative flex items-center min-h-screen dark:bg-dark bg-white bg-cover bg-[70%_top] sm:[50%_top] md:bg-center overflow-hidden" style={{ backgroundImage: `url(${bannerBg})` }}>
    	<div className="max-w-full md:max-w-7xl mx-auto w-full h-full md:my-auto px-4 md:px-0 md:flex items-center">
    		<div className="z-20 relative pb-8 max-w-full lg:max-w-3xl lg:w-full">
    			<main className="mt-10 mx-auto max-w-full lg:max-w-8xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
    				<div className="sm:text-center lg:text-left">
    					<h1 className="relative tracking-tight font-extrabold font-display dark:text-white text-gray-900 text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl">
    						<div className="block mb-3">WHAT IS</div>
    						<div className="block mb-3"><span className="GradientFAM">FAM</span> DAO?</div>
    					</h1>
    					<p className="mt-3 leading-8 text-xl  sm:leading-12 dark:text-white text-white sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">
    						FAMDAO is a Web3 decentralized autonomous ecosystem platform built on a self-governing protocol and designed with ANFS powerful technical support as the underlying technology. It was initiated and constructed by a group of crypto geeks.
    					</p>
    				</div>
    			</main>
    		</div>
    	</div>
    	
    </div>
  );
}

