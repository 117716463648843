
import why1 from '../../../assets/v2/whybg1.png';
import why2 from '../../../assets/v2/whybg2.png';
import why3 from '../../../assets/v2/whybg3.png';
import why4 from '../../../assets/v2/whybg4.png';

import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
	
  return (
    <div className="dark:bg-dark bg-white flex">
    	<div className="w-full pt-32 pb-8 sm:pt-32 sm:pb-32">
    		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mb-16">
    			<div className="text-center text-4xl sm:text-5xl md:text-6xl dark:text-white text-gray-800 mb-5 leading-tight mb-10">
    				Why FAMDAO?
    			</div>
    			<div className="mt-8 text-base sm:text-2xl dark:text-gray-500 text-gray-900">
    				FAMDAO is driven by a DAO to achieve community consensus and harness platform traffic. It's decentralized, fully transparent, and autonomously governed by core community members. They have the power to make crucial decisions in shaping the future of the platform.
    			</div>
    		</div>
    		
    		<ul className="WHY max-w-7xl mx-auto grid auto-rows-max grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-x-8 sm:gap-y-8 px-4 sm:px-6 lg:px-6">
    			<li className="col-span-1" data-aos="zoom-in">
    				<div className="bg-no-repeat bg-right-bottom bg-contain" style={{ backgroundImage: `url(${why1})` }}>
    					<div className="px-6 sm:px-10 pt-6 sm:pt-10 dark:text-white text-gray-800  w-full md:w-4/5">
    						<div className="font-medium text-2xl sm:text-2xl mb-3 leading-tight">DAO Governance</div>
    						<div className="font-medium text-sm leading-tight text-gray-500">
    							Address segment requirements based on DAO governance<br/>
    							Personalized customization<br/>
    							Open, transparent, and secure
    						</div>
    					</div>
    				</div>
    			</li>
    			<li className="col-span-1" data-aos="zoom-in" data-aos-delay="100">
    				<div className="bg-no-repeat bg-right-bottom md:bg-center bg-contain md:bg-cover" style={{ backgroundImage: `url(${why2})` }}>
    					<div className="px-6 sm:px-10 pt-6 sm:pt-10 dark:text-white text-gray-800 w-full md:w-4/5">
    						<div className="font-medium text-2xl sm:text-2xl mb-3 leading-tight">Applications</div>
    						<div className="font-medium text-sm leading-tight text-gray-500">
    							Multichain polymerization <br/>
    							More comprehensive sectors<br/>
    							More functions <br/>
    							Focus on user experience<br/>
    							Continuous value creation
    						</div>
    					</div>
    				</div>
    			</li>
    			<li className="col-span-1" data-aos="zoom-in"  data-aos-delay="200">
    				<div className="bg-no-repeat bg-right-bottom md:bg-center bg-contain md:bg-cover" style={{ backgroundImage: `url(${why3})` }}>
    					<div className="px-6 sm:px-10 pt-6 sm:pt-10 dark:text-white text-gray-800 w-full md:w-4/5">
    						<div className="font-medium text-2xl sm:text-2xl mb-3 leading-tight">Tokenize Value</div>
    						<div className="font-medium text-sm leading-tight text-gray-500">
    							Equity token, Governance, Voting, Dividends <br/>
    							More application scenarios, Ecological rights and <br/>
    							interests, Extreme deflation <br/>
    							Initial release, Airdrop, NFT launch, etc
    						</div>
    					</div>
    				</div>
    			</li>
    			<li className="col-span-1" data-aos="zoom-in">
    				<div className="bg-no-repeat bg-right-bottom md:bg-center bg-contain md:bg-cover" style={{ backgroundImage: `url(${why4})` }}>
    					<div className="px-6 sm:px-10 pt-6 sm:pt-10 dark:text-white text-gray-800 w-full md:w-4/5">
    						<div className="font-medium text-2xl sm:text-2xl mb-3 leading-tight">Delegate authority</div>
    						<div className="font-medium text-sm leading-tight text-gray-500">
    							Value ownership goes back to the users themselves<br/>
    							Offer a more socially conscious framework<br/>
    							Addressing trust and security issues<br/>
    							Providing incentives for contributors
    						</div>
    					</div>
    				</div>
    			</li>
    		</ul>
    	</div>
    </div>
  );
}

