import React,{useState,useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import new1 from '../../../assets/plan.png';
import new2 from '../../../assets/new2.png';
import new3 from '../../../assets/new3.png';
import new4 from '../../../assets/new4.png';
import new5 from '../../../assets/FamDAOLove.png';
import new6 from '../../../assets/new6.jpg';
import new7 from '../../../assets/LeadingPartners.jpg';

import NEWbg1 from '../../../assets/v2/new1.png';
import NEWbg2 from '../../../assets/v2/new2.png';
import NEWbg3 from '../../../assets/v2/new3.jpg';

import logo from '../../../assets/logo.png';

export default ()=> {
	// <div className="backdrop-filter backdrop-blur-xl rounded-xl">
	// 	<img className="h-16 py-4 px-2" src={logo}/>
	// </div>
	useEffect(() => {
		 AOS.init();
	}, [])
	let news = [{
		href:'https://twitter.com/FAM_DAO/status/1655841482534977538',
		pic:NEWbg1,
		title:'The FAMDAO Team is launching a 20-month Continuous Upgrade Plan',
		date:'Jul 15, 2022'
	},
	// {
	// 	href:'https://medium.com/@famdao/famdao-reflections-official-1st-month-of-operations-3f277b08c1b2',
	// 	pic:new2,
	// 	title:'FAMDAO Reflections: Official 1st Month of Operations!',
	// 	date:'Jun 27, 2022'
	// },{
	// 	href:'https://medium.com/@famdao/famdao-2022-roadmap-updates-governance-ecology-and-data-3c2951af8a68',
	// 	pic:new3,
	// 	title:'FAMDAO: 2022 Roadmap Updates, Governance, Ecology and Data',
	// 	date:'Jun 22, 2022'
	// },{
	// 	href:'https://cryptomode.com/famdao-the-dao-guided-by-a-family-treasury/',
	// 	pic:new4,
	// 	title:'FamDAO – The DAO guided by a FAMILY Treasury',
	// 	date:'March 17, 2022'
	// },
	{
		href:'https://twitter.com/FAM_DAO/status/1657285331929165827',
		pic:NEWbg2,
		title:'FamDAO – A DAO based on Family and Love',
		date:'April 4, 2022'
	},
	// {
	// 	href:'https://cryptomode.com/famdao-salvation-in-the-post-pandemic-era/',
	// 	pic:new6,
	// 	title:'FAMDAO, Salvation in the Post-Pandemic Era',
	// 	date:'April 4, 2022'
	// },
	{
		href:'https://twitter.com/FAM_DAO/status/1638817906296508416',
		pic:NEWbg3,
		title:'FamDAO, the Multi-Chain Family Solution, Announces Funding Plans by Leading Partners',
		date:'March 20, 2022'
	}]
	
	
  return (
    <div className="dark:bg-dark bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 pt-16 sm:pt-32">
    	<div className="max-w-7xl mx-auto px-0 sm:px-4 sm:px-6 lg:px-6">
    		<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-5 text-center">
    			News and Events
    		</div>
    		<div className="text-xl sm:text-2xl dark:text-white text-gray-800 mb-8 sm:mb-12">
    			
    		</div>
    	</div>
    	<div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-x-8 gap-y-8 ">
    		{
    			news.map((item,index)=>{
    				return (
    					<a data-aos="flip-right" key={index} href={item.href} target="_blank" className="group dark:bg-gray-800 bg-gray-50 dark:hover:bg-gray-700 hover:bg-gray-100 rounded-5xl">
    						<div className="h-48 bg-cover bg-center overflow-hidden rounded-3xl flex items-center justify-center" style={{ backgroundImage: `url(${item.pic})` }}>
    							<div className="hidden p-4 bg-gradient-to-b from-[NEWS-form] to-transparent">
    								<div className="font-bold text-white">
    									{item.title}
    								</div>
    								<div className="mt-1 text-white group-hover:opacity-100 opacity-90">
    									{item.date}
    								</div>
    							</div>
								
    						</div>
    					</a>
    				)
    			})
    		}
    	</div>
    </div>
  );
}

