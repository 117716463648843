import React,{useContext,useEffect} from 'react'
import { themeContext } from '../../../store/configProvider.tsx'
import AOS from 'aos';
import 'aos/dist/aos.css';

import roadMap from '../../../assets/roadMap.png';
import roadMap_light from '../../../assets/roadMap_light.png';
import line from '../../../assets/line.png';

import earth1 from '../../../assets/v2/earth1.png';
import earth2 from '../../../assets/v2/earth2.png';
import earth3 from '../../../assets/v2/earth3.png';
import earth4 from '../../../assets/v2/earth4.png';

import shadow from '../../../assets/v2/shadow.png';

export default ()=> {
	const { theme } = React.useContext(themeContext)
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="relative max-w-7xl mx-auto pt-16 sm:pt-32">
    	<div className="max-w-7xl mx-auto px-0 sm:px-4 sm:px-6 lg:px-6">
    		<div className="text-5xl sm:text-6xl dark:text-white text-gray-800 mb-5 text-center">
    			ROADMAP
    		</div>
    	</div>
    	<div data-aos="fade-up" className="relative z-10 rounded-5xl h-[48rem] md:h-[32rem] bg-contain bg-center bg-no-repeat" style={{ backgroundImage: `url(${theme=='dark'?roadMap:roadMap_light})` }}>
			<div className="absolute z-10 w-full h-full grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 px-4 py-2">
				<div className="relative md:top-64 pb-12 sm:pb-0">
					<div className="flex flex-col col-span-1 relative" data-aos="fade-right">
						<div className="my-2 text-xl">
							Q2 2022
						</div>
						<div className="text-sm text-gray-400">
							Platform development<br/>
							Internal test stage in April <br/>
							Launched BNB Chain officially on May 24 <br/>
							Launch ecological plan<br/>
							Start community construction
						</div>
						<div className="absolute -bottom-10 right-0 w-full">
							<div className="flex justify-end">
								<img className="p-4 w-1/3 md:w-2/4" src={earth1}/>
							</div>
							<div className="h-4 w-full bg-gradient-to-l from-[RoadMap-form1] to-[RoadMap-to1] rounded-2xl relative">
								<div className="rounded-full w-6 h-6 absolute -top-1 right-0 bg-RoadMap1"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="relative md:top-36  pb-12 sm:pb-0">
					<div className="flex flex-col col-span-1 relative" data-aos="fade-up" data-aos-delay="100">
						<div className="my-2 text-xl">
							Q3 2022
						</div>
						<div className="text-sm text-gray-400">
							Established global community in 12 countries <br/>
							Increased platform functions  (KLine, more languages) <br/>
							Governance on the online chain <br/>
							Launch LuckyFam <br/>
							Launch Lucky3
						</div>
						<div className="absolute -bottom-10 right-0 w-full">
							<div className="flex justify-end">
								<img className="p-4 w-1/3 md:w-2/4" src={earth2}/>
							</div>
							<div className="h-4 w-full bg-gradient-to-l from-[RoadMap-form1] to-[RoadMap-to1] rounded-2xl relative">
								<div className="rounded-full w-6 h-6 absolute -top-1 right-0 bg-RoadMap1"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="relative md:top-24  pb-12 sm:pb-0">
					<div className="flex flex-col col-span-1 relative" data-aos="fade-down" data-aos-delay="300">
						<div className="my-2 text-xl">
							Q4 2022
						</div>
						<div className="text-sm text-gray-400">
							13k+ Global communities<br/>
							11k+ Currency address<br/>
							Launch FAMDAO V2.0 <br/>
							Launch IFO/IDO/STO segments<br/>
							Airdrop release
						</div>
						<div className="absolute -bottom-8 right-0 w-full">
							<div className="flex justify-end">
								<img className="p-4 w-1/3 md:w-2/4" src={earth3}/>
							</div>
							<div className="h-4 w-full bg-gradient-to-l from-[RoadMap-form2] to-[RoadMap-to2] rounded-2xl relative">
								<div className="rounded-full w-6 h-6 absolute -top-1 right-0 bg-RoadMap2"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="relative md:top-12 pb-12 sm:pb-0">
					<div className="flex flex-col col-span-1 relative" data-aos="fade-left"  data-aos-delay="300">
						<div className="my-2 text-xl">
							2023
						</div>
						<div className="text-sm text-gray-400">
							60k+ Global community<br/>
							FAMDAO Governance V2 release<br/>
							LuckyFam board launched 1000+ games <br/>
							Access to Polygon ecology <br/>
							Access to Ethereum ecology
						</div>
						<div className="absolute -bottom-8 right-0 w-full">
							<div className="flex justify-end">
								<img className="p-4 w-1/3 md:w-2/4" src={earth4}/>
							</div>
							<div className="h-4 w-full bg-gradient-to-l from-[RoadMap-form2] to-[RoadMap-to2] rounded-2xl relative">
								<div className="rounded-full w-6 h-6 absolute -top-1 right-0 bg-RoadMap2"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
    	</div>
		
		<div className="absolute -top-16 -right-12 h-[150%]">
			<img src={shadow} className='h-full'/>
		</div>
    </div>
  );
}

