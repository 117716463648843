import React,{useState} from 'react'

import Banner from './Banner'
import Dao from './Dao'

export default ()=> {
  return (
	<div className="main">
		<Banner />
		<Dao />
	</div>
  );
}

