import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import DAObg from '../../../assets/v2/DAObg.png';
import shadow from '../../../assets/v2/shadow.png';

export default ()=> {
	useEffect(() => {
		 AOS.init();
	}, [])
  return (
    <div className="">
    	<div className="w-full pt-16">
    		<div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-6">
    			<div className="sm:flex justify-center items-center">
					<img  data-aos="zoom-in" className="p-4 w-120 sm:w-200 mr-12" src={DAObg}/>
    				<div className="text-center sm:text-left">
						<div className="text-5xl dark:text-white text-gray-900">ABOUT FAMDAO</div>
						<div className="mt-8 text-base sm:text-xl dark:text-gray-500 text-gray-900">
							FAMDAO was originally initiated by a group of crypto geeks, essentially a Decentralized Governance Organization (DAO), where the value it creates flows to token holders and returns to the ecosystem, promoting a virtuous cycle of ecosystem. All Web3 builders can gather together to collaborate and build different ecological applications. The governance token (FAM) empowers holders with the rights to propose, vote, and supervise to manage the FAMDAO ecosystem. The shared ownership in DAO encourages builders to pursue common goals and develop together.
						</div>
					</div>
    			</div>
    			<div className="absolute -top-1/4 -right-12 h-[150%]">
    				<img src={shadow} className='h-full'/>
    			</div>
    		</div>
    	</div>
    </div>
  );
}

